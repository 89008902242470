import Colors from "styles/colors";
import { BLUE_COLOR_NAME, BRAND_BLACK_TEXT_COLOR, BRAND_DARK_COLOR_NAME, BRAND_GREEN_DARK_TINT_01_COLOR_NAME, BRAND_SERVICE_DARK_COLOR_NAME, BRAND_SERVICE_PRIMARY_COLOR_NAME, BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME, CRM_DARK_TINT_01_COLOR_NAME, DARK_CRM_GREEN_COLOR_NAME, DARK_DEV_GREEN_COLOR_NAME, DARK_MARKETING_RED_COLOR_NAME, DARK_PROJECTS_ORANGE_COLOR_NAME, DODGER_BLUE_COLOR_NAME, ELEVATE_BUTTON_GRADIANT_COLOR_NAME, IRIS_COLOR_NAME, SPACE_BLUE_COLOR_NAME, WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME, WORKFORMS_RED_COLOR_NAME } from "styles/color-consts";
export const ELEVATE_BUTTON_COLLOR_NAME = ELEVATE_BUTTON_GRADIANT_COLOR_NAME;
export const buttonColors = [
    WORK_OS_IRIS_COLOR_NAME,
    WHITE_COLOR_NAME,
    BRAND_BLACK_TEXT_COLOR,
    WORKFORMS_RED_COLOR_NAME
]; // after removing old colors - change to:  Object.keys(buttonStyles);
export const buttonStyles = {
    [IRIS_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: IRIS_COLOR_NAME
    },
    [WORK_OS_IRIS_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: WORK_OS_IRIS_COLOR_NAME
    },
    [BLUE_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: BLUE_COLOR_NAME
    },
    [DODGER_BLUE_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: DODGER_BLUE_COLOR_NAME
    },
    [SPACE_BLUE_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: SPACE_BLUE_COLOR_NAME
    },
    [BRAND_BLACK_TEXT_COLOR]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: BRAND_BLACK_TEXT_COLOR
    },
    [WHITE_COLOR_NAME]: {
        textColor: Colors[BRAND_BLACK_TEXT_COLOR],
        backgroundColorName: WHITE_COLOR_NAME
    },
    [WORKFORMS_RED_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: WORKFORMS_RED_COLOR_NAME
    },
    [DARK_CRM_GREEN_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: DARK_CRM_GREEN_COLOR_NAME
    },
    [DARK_MARKETING_RED_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: DARK_MARKETING_RED_COLOR_NAME
    },
    [DARK_PROJECTS_ORANGE_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: DARK_PROJECTS_ORANGE_COLOR_NAME
    },
    [DARK_DEV_GREEN_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: DARK_DEV_GREEN_COLOR_NAME
    },
    [ELEVATE_BUTTON_COLLOR_NAME]: {
        textColor: Colors[BRAND_BLACK_TEXT_COLOR],
        backgroundColorName: ELEVATE_BUTTON_GRADIANT_COLOR_NAME
    },
    [BRAND_GREEN_DARK_TINT_01_COLOR_NAME]: {
        textColor: Colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME],
        backgroundColorName: BRAND_GREEN_DARK_TINT_01_COLOR_NAME
    },
    [BRAND_DARK_COLOR_NAME]: {
        textColor: Colors[BRAND_DARK_COLOR_NAME],
        backgroundColorName: BRAND_DARK_COLOR_NAME
    },
    [CRM_DARK_TINT_01_COLOR_NAME]: {
        textColor: Colors[CRM_DARK_TINT_01_COLOR_NAME],
        backgroundColorName: CRM_DARK_TINT_01_COLOR_NAME
    },
    [BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME]: {
        textColor: Colors[BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME],
        backgroundColorName: BRAND_WORK_MANAGEMENT_PRIMARY_COLOR_NAME
    },
    [BRAND_SERVICE_DARK_COLOR_NAME]: {
        textColor: Colors[BRAND_SERVICE_DARK_COLOR_NAME],
        backgroundColorName: BRAND_SERVICE_DARK_COLOR_NAME
    },
    [BRAND_SERVICE_PRIMARY_COLOR_NAME]: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: BRAND_SERVICE_PRIMARY_COLOR_NAME
    },
    // old colors - kept for supporting old page configs:
    pink: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: "pink"
    },
    purple: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: "purple"
    },
    royal: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: "royal"
    },
    green: {
        textColor: Colors[WHITE_COLOR_NAME],
        backgroundColorName: "green"
    },
    yellow: {
        textColor: Colors.mud,
        backgroundColorName: "yellow"
    },
    orange: {
        textColor: Colors.mud,
        backgroundColorName: "orange"
    },
    "light-blue": {
        textColor: Colors.mud,
        backgroundColorName: "light-blue-video-background"
    }
};
