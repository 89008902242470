import styled from "@emotion/styled";
export const StyledBypassBlocksMenuComponent = styled.nav`
  .menu-item {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;

    &:focus {
      opacity: 1;
      pointer-events: auto;
    }
  }
`;
