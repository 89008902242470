function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
import { PAGE_USAGE_DURATION } from "../constants/bigbrain-event-types";
import { sleep } from "./utils-service";
import { getScrollPercent } from "./dom-utils-service";
const MAX_SECONDS = 60;
const TIMEOUT = 5 * 1000;
const TRACK_PERCENTAGE = 10;
const track = (timeSinceStarted, scrollPercent)=>{
    BigBrain("track", PAGE_USAGE_DURATION, {
        kind: window.visitGuid,
        info1: timeSinceStarted,
        info2: scrollPercent,
        info3: location.href
    });
};
const iterateTracker = function() {
    var _ref = _async_to_generator(function*(startedAt) {
        const scrollPercent = yield getScrollPercent();
        const secondsSinceStarted = Math.floor((new Date().getTime() - startedAt) / 1000);
        track(secondsSinceStarted, scrollPercent);
        if (MAX_SECONDS > secondsSinceStarted) {
            yield sleep(TIMEOUT);
            iterateTracker(startedAt);
        }
    });
    return function iterateTracker(startedAt) {
        return _ref.apply(this, arguments);
    };
}();
const shouldTrack = ()=>{
    return TRACK_PERCENTAGE > 100 * Math.random();
};
export const initPageUsageDurationTracking = (startedAt)=>{
    if (!shouldTrack()) return;
    iterateTracker(startedAt);
};
