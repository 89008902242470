import { getCookie } from "./cookies-service";
import { EXPERIMENT_VISITOR_ID } from "/constants/cookies";
import { WEBSITE_SYNC_VISITOR_AND_AB_TEST_EXPERIMENT_ID } from "constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
export const fireBigbrainVisitorExperimentSyncEvent = ()=>{
    const experimentId = getCookie(EXPERIMENT_VISITOR_ID);
    if (experimentId) {
        trackEvent(WEBSITE_SYNC_VISITOR_AND_AB_TEST_EXPERIMENT_ID, {
            kind: experimentId
        });
    }
};
