import { useEffect } from "react";
import { LANDING_PAGE } from "constants/localstorage";
import { isServerSide } from "utils/general";
const LandingPageTracker = ()=>{
    useEffect(()=>{
        if (isServerSide()) {
            return;
        }
        const landingPage = window.location.pathname;
        // don't store landing page if it exists already
        if (localStorage.getItem(LANDING_PAGE)) return;
        localStorage.setItem(LANDING_PAGE, JSON.stringify({
            value: landingPage,
            timestamp: Date.now()
        }));
    }, []);
    return null;
};
export default LandingPageTracker;
