import { DOM_CHANGED, DOM_CHANGED_UNAVAILABLE, DOM_CHANGE_EVENT_FAILURE } from "../constants/bigbrain-event-types";
const WHITELISTED_CLASSES_PATTERNS = [
    "ReactModalPortal"
];
const WHITELISTED_IDS_PATTERNS = [
    "monday_tools_extension_id",
    "batBeacon",
    "_hjRemoteVarsFrame"
];
const WHITELISTED_SRC_PATTERNS = [
    "8175736.fls.doubleclick.net",
    "comeet.com"
];
const findPatternMatchInListForAttribute = (list, attribute)=>{
    return list.some((pattern)=>{
        return attribute.includes(pattern);
    });
};
const shouldSkipNode = (node)=>{
    const id = node.id;
    if (id && findPatternMatchInListForAttribute(WHITELISTED_IDS_PATTERNS, id)) return true;
    const classname = node.className;
    if (classname && findPatternMatchInListForAttribute(WHITELISTED_CLASSES_PATTERNS, classname)) return true;
    const src = node.src;
    if (src && findPatternMatchInListForAttribute(WHITELISTED_SRC_PATTERNS, src)) return true;
};
export const initDomObserving = ()=>{
    // helping tracking changes in the page. Used for analysis of chrome extension iframes
    // paused as we done using it, but keep it in the code as it might be useful in the future for more stuff
    const shouldObserve = process.browser && false; // always/upon draw for certain %
    if (!shouldObserve) return;
    try {
        if (!window.MutationObserver) {
            BigBrain("track", DOM_CHANGED_UNAVAILABLE, {
                kind: "not supporting mutation observer"
            });
            return;
        }
        const loadedAt = new Date().getTime();
        const targetNode = document.body;
        const observerConfig = {
            childList: true
        };
        const observer = new MutationObserver((mutations)=>{
            mutations.forEach((mutation)=>{
                mutation.addedNodes && mutation.addedNodes.forEach((node)=>{
                    try {
                        if (shouldSkipNode(node)) return;
                        window.requestAnimationFrame(()=>{
                            let boundingClientRect;
                            try {
                                boundingClientRect = node.getBoundingClientRect && node.getBoundingClientRect().toJSON();
                            } catch (e) {}
                            BigBrain("track", DOM_CHANGED, {
                                placement: node.id,
                                kind: node.className,
                                info1: node.href,
                                info2: node.src,
                                info3: node.type,
                                direct_object_id: new Date().getTime() - loadedAt,
                                direct_object: window.visitGuid,
                                board_kind: node.nodeName,
                                data: boundingClientRect
                            });
                        });
                    } catch (e) {
                        BigBrain("track", DOM_CHANGE_EVENT_FAILURE, {
                            kind: e.message || "n/a"
                        });
                    }
                });
            });
        });
        observer.observe(targetNode, observerConfig);
    } catch (e) {
        BigBrain("track", DOM_CHANGED_UNAVAILABLE, {
            kind: e.message || "n/a"
        });
    }
};
