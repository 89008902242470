interface AbTest {
  test_name: string;
  new_variation_name: string;
}

export const PURCHASE_NOW_AB_TEST: AbTest = {
  test_name: "purchase_now_ab_test",
  new_variation_name: "new_with_purchase_now_button"
};

export const PRICING_SUB_TITLE_V2_AB_TEST: AbTest = {
  test_name: "pricing_page_sub_title_v2_ab_test",
  new_variation_name: "new_pricing_with_sub_title_v2"
};

export const HOMEPAGE_PLATFORM_PRICING_CONTACT_US_CTA_AB_TEST: AbTest = {
  test_name: "homepage_platform_pricing_contact_us_cta_ab_test",
  new_variation_name: "new_cta_with_talk_to_sales"
};

export const HOMEPAGE_PRICING_ENTERPRISE_CONTACT_US_TYPEFORM_FEATURE_FLAG: AbTest = {
  test_name: "homepage_pricing_enterprise_contact_us_typeform_feature_flag",
  new_variation_name: "new_homepage_pricing_enterprise_contact_us_typeform_feature_flag"
};
