import { useEffect, useRef, useState } from "react";
const useResourcePerformanceTracking = (resourceName, onLoadComplete)=>{
    const [resource, setResource] = useState(null);
    const observer = useRef(null);
    useEffect(()=>{
        var _window;
        if (!((_window = window) === null || _window === void 0 ? void 0 : _window.PerformanceObserver)) {
            return;
        }
        observer.current = new PerformanceObserver((list)=>{
            const entry = list.getEntries().find((performanceEntry)=>performanceEntry.name.includes(resourceName));
            if (entry) {
                onLoadComplete && onLoadComplete(entry.duration, entry.startTime, entry.transferSize);
                setResource(entry);
            }
        });
        observer.current.observe({
            type: "resource",
            buffered: true
        });
        return ()=>{
            observer.current.disconnect();
        };
    }, [
        onLoadComplete,
        resourceName
    ]);
    useEffect(()=>{
        resource && observer.current.disconnect();
    }, [
        resource
    ]);
};
export default useResourcePerformanceTracking;
