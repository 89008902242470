import { setCookieIfNeeded } from "./cookies-service";
import { SKIP_CLUSTER_QUESTION } from "../constants/cookies";
import { SET_SKIP_QUESTION_COOKIE } from "../constants/bigbrain-event-types";
export const setSkipQuestionsCookieIfNeeded = (skipClustersQuestion)=>{
    if (skipClustersQuestion) {
        setCookieIfNeeded(SKIP_CLUSTER_QUESTION, "true", {
            expires: 3
        }, SET_SKIP_QUESTION_COOKIE);
    }
};
