import { CONSTRUCTION, CREATIVE, CRM, DESIGN_AND_CREATIVE, FINANCE, FUNDRAISING, HR, IT, MARKETING, NON_PROFIT, OPERATIONS, PMO, PRODUCT_MANAGEMENT, PROJECTS_FLOW, SOFTWARE, TASKS_AND_TODOS, WORK_MANAGEMENT } from "constants/clusters";
import { CRM_PRODUCT_ID, DEV_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
const CLUSTER_TO_PRODUCT_ID = {
    [CONSTRUCTION]: WORK_MANAGEMENT_PRODUCT_ID,
    [HR]: WORK_MANAGEMENT_PRODUCT_ID,
    [OPERATIONS]: WORK_MANAGEMENT_PRODUCT_ID,
    [IT]: WORK_MANAGEMENT_PRODUCT_ID,
    [PMO]: WORK_MANAGEMENT_PRODUCT_ID,
    [PRODUCT_MANAGEMENT]: DEV_PRODUCT_ID,
    [DESIGN_AND_CREATIVE]: WORK_MANAGEMENT_PRODUCT_ID,
    [TASKS_AND_TODOS]: WORK_MANAGEMENT_PRODUCT_ID,
    [FINANCE]: WORK_MANAGEMENT_PRODUCT_ID,
    [CREATIVE]: WORK_MANAGEMENT_PRODUCT_ID,
    [CRM]: CRM_PRODUCT_ID,
    [SOFTWARE]: DEV_PRODUCT_ID,
    [WORK_MANAGEMENT]: WORK_MANAGEMENT_PRODUCT_ID,
    [MARKETING]: WORK_MANAGEMENT_PRODUCT_ID,
    [FUNDRAISING]: WORK_MANAGEMENT_PRODUCT_ID,
    [NON_PROFIT]: WORK_MANAGEMENT_PRODUCT_ID,
    [PROJECTS_FLOW]: WORK_MANAGEMENT_PRODUCT_ID
};
export const getProductTypeFromCluster = (clusterId)=>CLUSTER_TO_PRODUCT_ID[clusterId];
