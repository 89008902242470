const ALIGN_TYPES = {
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right"
};
const ALIGN_LABELS = {
    LEFT: "Left",
    CENTER: "Center",
    RIGHT: "Right"
};
const VERTICAL_ALIGN_LABELS = {
    TOP: "Top",
    CENTER: "Center",
    BOTTOM: "Bottom"
};
module.exports = {
    ALIGN_TYPES,
    ALIGN_LABELS,
    VERTICAL_ALIGN_LABELS
};
