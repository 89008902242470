import styled from "@emotion/styled";
import Colors from "styles/colors";
import { getHoverColor } from "styles/colors-utils";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
const getButtonTheme = (color)=>`
      &:not(.outline) {
        background-color: ${color};

        &:hover {
          background-color: darken(${color}, 5%);
        }
      }

      &.outline {
        color: ${color};
        border: 1px solid ${color} !important;
        transition: background-color 200ms ease, color 200ms ease;

        &:hover {
          color: ${Colors.white} !important;
          background-color: ${color};
          &.white {
            color: ${Colors.black} !important;
          }
        }
      }
`;
export const StyledButtonComponent = styled.div`
  display: inline-block;
  border-radius: 100px;
  cursor: pointer;
  font-weight: 300;
  user-select: none;
  transition: background-color 200ms ease;

  ${({ accessibleColor })=>`
  &[data-button-color="${accessibleColor}"] {
              &:not(.outline) {
                background: ${Colors[accessibleColor]};
                &:hover {
                  background-color: ${getHoverColor(Colors[accessibleColor])};
                  &.white {
                    background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
                  }
                }
              }
            }
  `}

  &:hover {
    outline: 0;
  }

  &.disabled {
    background-color: ${Colors["dark-marble"]} !important;
    color: ${Colors["brand-gray"]} !important;
    pointer-events: none;
  }

  // default size: md
  font-size: 0.8125rem;
  padding: 12px 24px;

  &.with-arrow {
    padding-right: 16px;
  }

  &.with-left-icon {
    height: 40px;
    display: flex;
    align-items: center;
  }

  &.sm {
    font-size: 0.6875rem;
    padding: 8px 16px;

    &.with-arrow {
      padding-right: 12px;
    }
  }

  &.md {
    font-size: 0.8125rem;
    padding: 12px 24px;

    &.min-padding {
      padding-right: 10px;
      padding-left: 10px;
    }

    &.with-arrow {
      padding-right: 16px;
    }
  }

  &.lg {
    font-size: 0.875rem;
    padding: 16px 32px;

    &.with-arrow {
      padding-left: 30px;
      padding-right: 20px;
    }
  }

  &.full {
    font-size: 0.875rem;
    padding: 16px 0;
    width: 100%;
    text-align: center;
  }

  &.blue {
    ${getButtonTheme("#0085ff")}
  }

  &.mint-green {
    ${getButtonTheme("#25dbc5")}
  }

  &.bold-blue {
    ${getButtonTheme("#15bbe4")}
  }

  &.purple {
    ${getButtonTheme("#a358df")}
  }

  &.iris {
    ${getButtonTheme("#595ad4")}
  }

  &.riverstone {
    ${getButtonTheme("#f5f6f8")}
  }

  &.royal {
    ${getButtonTheme("#597bfc")}
  }

  &.green {
    ${getButtonTheme("#00ca72")}
  }

  &.orange {
    ${getButtonTheme("#fdab3d")}
  }

  &.yellow {
    ${getButtonTheme("#ffcb00")}
  }

  &.pink {
    ${getButtonTheme("#ff0476")}
  }

  &.marketing-red-dark {
    ${getButtonTheme("#ca0c6b")}
  }

  &.dev-green-dark {
    ${getButtonTheme("#037f4c")}
  }

  &.projects-orange-dark {
    ${getButtonTheme("#f86700")}
  }

  &.crm-green-dark {
    ${getButtonTheme("#00a0a0")}
  }

  &.workos-iris {
    ${getButtonTheme("#6161ff")}
  }

  &.dark-color {
    ${getButtonTheme("#181b34")}
  }

  &.brand-wm-primary-color {
    ${getButtonTheme("#6161ff")}
  }

  &.crm-dark-tint-01 {
    ${getButtonTheme("#00889b")}
  }

  &.brand-green-dark-tint-01 {
    ${getButtonTheme("#00854d")}
  }

  &.white {
    ${getButtonTheme("#ffffff")}
  }

  &.black {
    ${getButtonTheme("#000000")}
  }

  &.workforms-red {
    ${getButtonTheme("#c21e71")}
  }

  &.service-dark-color {
    ${getButtonTheme("#980131")}
  }
`;
