const HEADER_HEIGHT = 72;
export const PLANS_HEADERS_OFFSET = HEADER_HEIGHT;
export const TIERS_STICKY_HEADER_OFFSET = HEADER_HEIGHT;
export const TIERS_STICKY_HEADER_BORDER_OFFSET = 107; // sticky header height (82) + first title height (25)
export const PLANS_COMPONENT_WIDTH = 1225;
export const TIER_CARD_PRICE_HEIGHT = 128;
export const FREE_TIER_PRICE_HEIGHT = 142;
export const TIER_CARD_VIDEO_WIDTH = 422;
export const TIER_CARD_VIDEO_HEIGHT = 284;
export const CLEAN_FIRST_FOLD_MARGIN = "10%";
