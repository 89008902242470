import { useCallback } from "react";
import { trackEvent } from "services/bigbrain-service";
import { PAGE_PERFORMANCE_TRACKING_RESOURCES } from "constants/bigbrain-event-types";
import useResourcePerformanceTracking from "components/core/head/use-resource-performance-tracking";
const BUNDLE_SCRIPT_PREFIX = "dynamic-template-page";
const DynamicTemplatePageScriptPerformanceTracker = ()=>{
    const onLoadComplete = useCallback((loadingDurationTime, startTime, size)=>{
        trackEvent(PAGE_PERFORMANCE_TRACKING_RESOURCES, {
            kind: `${BUNDLE_SCRIPT_PREFIX} monitor`,
            info1: loadingDurationTime,
            info2: startTime,
            info3: size
        });
    }, []);
    useResourcePerformanceTracking(BUNDLE_SCRIPT_PREFIX, onLoadComplete);
    return null;
};
export default DynamicTemplatePageScriptPerformanceTracker;
