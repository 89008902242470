function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { PAGE_PERFORMANCE_TRACKING_DATA, PAGE_PERFORMANCE_TRACKING_ERROR } from "../../constants/bigbrain-event-types";
class PageLoadTracking {
    getPaintsData() {
        const paints = window.performance.getEntriesByType("paint");
        const paintsData = {};
        if (!paints) return paintsData;
        paints.map((paint)=>{
            const { name, startTime } = paint;
            paintsData[name] = startTime;
        });
        return paintsData;
    }
    getPerformenceData(data) {
        const { template, navigationStart, "first-paint": firstPaint, domInteractive, domContentLoadedEventEnd, loadEventStart } = data;
        return {
            template,
            firstPaint,
            timeToInteractive: domInteractive - navigationStart,
            timeToContentLoad: domContentLoadedEventEnd - navigationStart,
            timeToToalLoad: loadEventStart - navigationStart
        };
    }
    getInfoData(performanceData) {
        const { template, firstPaint, timeToInteractive, timeToContentLoad, timeToToalLoad } = performanceData;
        return {
            kind: template,
            info1: firstPaint,
            info2: timeToInteractive,
            info3: timeToContentLoad,
            direct_object_id: timeToToalLoad // time to total load
        };
    }
    setPerformenceOnWindow(performenceData) {
        window.mondayPerformence = performenceData;
    }
    track(template) {
        try {
            const timing = window.performance.timing.toJSON();
            const navigation = window.performance.navigation;
            const memory = window.performance.memory; //	not supported in firefox
            const paintsData = this.getPaintsData();
            const data = _object_spread_props(_object_spread(_object_spread_props(_object_spread({}, timing), {
                redirectCount: navigation && navigation.redirectCount
            }), paintsData), {
                totalJSHeapSize: memory && memory.totalJSHeapSize,
                template
            });
            const performanceData = this.getPerformenceData(data);
            this.setPerformenceOnWindow(performanceData);
            const infoData = this.getInfoData(performanceData);
            BigBrain("track", PAGE_PERFORMANCE_TRACKING_DATA, _object_spread_props(_object_spread({
                data
            }, infoData), {
                placement: this.pageLoadUniqueId
            }));
        } catch (error) {
            BigBrain("track", PAGE_PERFORMANCE_TRACKING_ERROR, {
                data: {
                    error: error.message
                },
                placement: this.pageLoadUniqueId
            });
        }
    }
    constructor(pageLoadUniqueId){
        this.pageLoadUniqueId = pageLoadUniqueId;
    }
}
export { PageLoadTracking as default };
