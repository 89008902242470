import { TIER_CARD_VIDEO_WIDTH, TIER_CARD_VIDEO_HEIGHT } from "constants/pricing-sizes";
export const UNLIMITED_BOARDS = {
    name: "pricingPage.features.titles.unlimitedBoards",
    text: "pricingPage.features.descriptions.boards"
};
export const UP_TO_THREE_BOARDS = {
    name: "pricingPage.features.titles.upTo3Boards",
    text: "pricingPage.features.descriptions.boards"
};
export const UNLIMITED_DOCS = {
    name: "pricingPage.features.titles.unlimitedDocs",
    text: "pricingPage.features.descriptions.docs"
};
export const UNLIMITED_ITEMS = {
    name: "pricingPage.features.titles.unlimitedItems",
    text: "pricingPage.features.descriptions.unlimitedItems"
};
export const FIVE_GB_STORAGE = {
    name: "pricingPage.features.titles.fiveGBStorage",
    text: "pricingPage.features.descriptions.storage"
};
export const UP_TO_X_TEAM_MEMBERS = (teamMembersCount)=>{
    return {
        name: {
            key: "pricingPage.features.titles.upToXMembers",
            param: teamMembersCount
        },
        text: {
            key: "pricingPage.features.descriptions.upToXMembers",
            param: teamMembersCount
        }
    };
};
export const PRIORITISED_CUSTOMER_SUPPORT = {
    name: "pricingPage.features.titles.prioritisedCustomerSupport",
    text: "pricingPage.features.descriptions.prioritisedCustomerSupport"
};
export const OVER_200_TEMPLATES = {
    name: "pricingPage.features.titles.templates",
    text: "pricingPage.features.descriptions.templates"
};
export const COLUMN_TYPES = {
    name: "pricingPage.features.titles.columnTypes",
    text: "pricingPage.features.descriptions.columnTypes"
};
export const LIMITED_COLUMN_TYPES = {
    name: "pricingPage.features.titles.limitedColumnTypes",
    text: "pricingPage.features.descriptions.columnTypes"
};
export const COMMUNICATION_WITH_CONTEXT = {
    name: "pricingPage.features.titles.communication_with_context",
    text: "pricingPage.features.descriptions.communication_with_context",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Communication_-_updates.mp4`
};
export const FORMS = {
    name: "pricingPage.features.titles.forms.simple",
    text: "pricingPage.features.descriptions.forms",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Form.mp4`
};
export const KANBAN_VIEW = {
    name: "pricingPage.features.titles.kanbanView",
    text: "pricingPage.features.descriptions.kanbanView"
};
export const WHITEBOARD = {
    name: "pricingPage.features.titles.whiteboard",
    text: "pricingPage.features.descriptions.whiteboard"
};
export const MOBILE = {
    name: "pricingPage.features.titles.mobile",
    text: "pricingPage.features.descriptions.mobile"
};
export const UNLIMITED_VIEWERS = {
    name: "pricingPage.features.titles.unlimitedViewers",
    text: "pricingPage.features.descriptions.viewers"
};
export const LEAD_MANAGEMENT = {
    name: "pricingPage.features.titles.leadManagement",
    text: "pricingPage.features.descriptions.leadManagement"
};
export const UNLIMITED_PIPELINES = {
    name: "pricingPage.features.titles.unlimitedPipelines",
    text: "pricingPage.features.descriptions.unlimitedPipelines"
};
export const UNLIMITED_CONTACTS = {
    name: "pricingPage.features.titles.unlimitedContacts",
    text: "pricingPage.features.descriptions.unlimitedContacts"
};
export const UNLIMITED_CUSTOM_FIELDS = {
    name: "pricingPage.features.titles.unlimitedCustomFields",
    text: "pricingPage.features.descriptions.unlimitedCustomFields"
};
export const FULL_MOBILE_CRM = {
    name: "pricingPage.features.titles.fullMobileCRM",
    text: "pricingPage.features.descriptions.fullMobileCRM"
};
export const DOCUMENT_MANAGEMENT = {
    name: "pricingPage.features.titles.documentManagement",
    text: "pricingPage.features.descriptions.documentManagement"
};
export const API_ACCESS = {
    name: "pricingPage.features.titles.apiAccess",
    text: "pricingPage.features.descriptions.apiAccess"
};
export const SINGLE_DASHBOARD = {
    name: "pricingPage.features.titles.basicDashboards",
    text: "pricingPage.features.descriptions.dashboards"
};
export const TIMELINE_AND_GANTT_VIEWS = {
    name: "pricingPage.features.titles.timelineAndGanttView",
    text: "pricingPage.features.descriptions.timelineAndGanttView"
};
export const TIMELINE = {
    name: "pricingPage.features.titles.timeline",
    text: "pricingPage.features.descriptions.timeline"
};
export const CALENDAR = {
    name: "pricingPage.features.titles.calendar",
    text: "pricingPage.features.descriptions.calendar"
};
export const TIMELINE_AND_CALENDAR = {
    name: "pricingPage.features.titles.timelineAndCalendarView",
    text: "pricingPage.features.descriptions.timelineAndCalendarView",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT}/remote_mondaycom_static/developers/features/Timeline_view.mp4`
};
export const FILTERS = {
    name: "pricingPage.features.titles.filters",
    text: "pricingPage.features.descriptions.filters"
};
export const SEARCH = {
    name: "pricingPage.features.titles.search",
    text: "pricingPage.features.descriptions.search"
};
export const GUESTS = {
    name: "pricingPage.features.titles.guests",
    text: "pricingPage.features.descriptions.guests"
};
export const EMAIL_INTEGRATION = {
    name: "pricingPage.features.titles.emailIntegration",
    text: "pricingPage.features.descriptions.emailIntegration"
};
export const SALES_ANALYTICS = {
    name: "pricingPage.features.titles.salesAnalytics",
    text: "pricingPage.features.descriptions.salesAnalytics"
};
export const MAILCHIMP_INTEGRATION = {
    name: "pricingPage.features.titles.mailchimpIntegration",
    text: "pricingPage.features.descriptions.mailchimpIntegration"
};
export const SALES_GOALS = {
    name: "pricingPage.features.titles.salesGoals",
    text: "pricingPage.features.descriptions.salesGoals"
};
export const STANDARD_DASHBOARDS = {
    name: "pricingPage.features.titles.standardDashboards",
    text: "pricingPage.features.descriptions.dashboards",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Dashboard_standard.mp4`
};
export const STANDARD_AUTOMATIONS = {
    name: "pricingPage.valueFeatures.titles.automations.standard",
    text: "pricingPage.features.descriptions.automations",
    link: "https://support.monday.com/hc/en-us/articles/360002826680",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Automations.mp4`
};
export const STANDARD_INTEGRATIONS = {
    name: "pricingPage.valueFeatures.titles.integrations.standard",
    text: "pricingPage.features.descriptions.integrations",
    link: "https://support.monday.com/hc/en-us/articles/360002826680",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Integrations.mp4`
};
export const PRIVATE_BOARDS = {
    name: "pricingPage.features.titles.privateBoards",
    text: "pricingPage.features.descriptions.privateBoards",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Private_board.mp4`
};
export const BOARD_PERMISSIONS = {
    name: "pricingPage.features.titles.boardPermissions",
    text: "pricingPage.features.descriptions.boardPermissions",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Board_permission.mp4`
};
export const CHART_VIEW = {
    name: "pricingPage.features.titles.chartView",
    text: "pricingPage.features.descriptions.chartView",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Chart_view.mp4`
};
export const TIME_TRACKING = {
    name: "pricingPage.features.titles.timeTracking",
    text: "pricingPage.features.descriptions.timeTracking"
};
export const FORMULA = {
    name: "pricingPage.features.titles.formula",
    text: "pricingPage.features.descriptions.formula"
};
export const DEPENDENCY = {
    name: "pricingPage.features.titles.dependencyColumn",
    text: "pricingPage.features.descriptions.dependencyColumn"
};
export const UNLIMITED_GUESTS = {
    name: "pricingPage.features.titles.unlimitedGuests",
    text: ""
};
export const CUSTOMIZED_NOTIFICATIONS = {
    name: "pricingPage.features.titles.customizedNotifications",
    text: "pricingPage.features.descriptions.customizedNotifications"
};
export const MARKETING_ACTIVITY_MANAGEMENT = {
    name: "pricingPage.features.titles.marketingActivityManagement",
    text: "pricingPage.features.descriptions.marketingActivityManagement"
};
export const ADVANCED_PERMISSIONS = {
    name: "pricingPage.features.titles.advancedPermissions",
    text: "pricingPage.features.descriptions.boardPermissions"
};
export const WORKFLOW_AND_APPROVAL_AUTOMATION = {
    name: "pricingPage.features.titles.workflowAndApprovalAutomation",
    text: "pricingPage.features.descriptions.workflowAndApprovalAutomation"
};
export const ADVANCED_REPORTS = {
    name: "pricingPage.features.titles.advancedReports",
    text: "pricingPage.features.descriptions.dashboards"
};
export const PRO_DASHBOARDS = {
    name: "pricingPage.features.titles.proDashboards",
    text: "pricingPage.features.descriptions.dashboards",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Dashboard.mp4`
};
export const PRO_AUTOMATIONS = {
    name: "pricingPage.valueFeatures.titles.automations.pro",
    text: "pricingPage.features.descriptions.automations",
    link: "https://support.monday.com/hc/en-us/articles/360002826680",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Automations.mp4`
};
export const PRO_INTEGRATIONS = {
    name: "pricingPage.valueFeatures.titles.integrations.pro",
    text: "pricingPage.features.descriptions.integrations",
    link: "https://support.monday.com/hc/en-us/articles/360002826680",
    videoPath: `https://dapulse-res.cloudinary.com/video/upload/w_${TIER_CARD_VIDEO_WIDTH},h_${TIER_CARD_VIDEO_HEIGHT},c_fit/remote_mondaycom_static/developers/features/Integrations.mp4`
};
export const ENTERPRISE_SCALE_AUTOMATIONS = {
    name: "pricingPage.features.titles.enterpriseScaleAutomations",
    text: "pricingPage.features.descriptions.enterpriseScaleAutomations",
    link: "https://support.monday.com/hc/en-us/articles/360002826680"
};
export const ENTERPRISE_SPECIFIC_INTEGRATIONS = {
    name: "pricingPage.features.titles.enterpriseSpecificIntegrations",
    text: "pricingPage.features.descriptions.enterpriseSpecifciIntegrations"
};
export const ENTERPRISE_GRADE_SECURITY = {
    name: "pricingPage.features.titles.enterpriseGradeSecurity",
    text: "pricingPage.features.descriptions.enterpriseGradeSecurity"
};
export const ADVANCED_REPORTING = {
    name: "pricingPage.features.titles.advancedReporting",
    text: "pricingPage.features.descriptions.advancedReporting"
};
export const MULTI_LEVEL_PERMISSIONS = {
    name: "pricingPage.features.titles.multiLevelPermissions",
    text: "pricingPage.features.descriptions.multiLevelPermissions"
};
export const TAILORED_ONBOARDING = {
    name: "pricingPage.features.titles.tailoredOnboarding",
    text: "pricingPage.features.descriptions.tailoredOnboarding"
};
export const PREMIUM_SUPPORT = {
    name: "pricingPage.features.titles.premiumSupport",
    text: "pricingPage.features.descriptions.premiumSupport"
};
export const ENTERPRISE_DASHBOARDS = {
    name: "pricingPage.features.titles.enterpriseDashboards",
    text: "pricingPage.features.descriptions.fiftyDashboards"
};
// added for marketing:
export const MARKETING_TEMPLATES = {
    name: "pricingPage.features.titles.ready_made_marketing_templates",
    text: "pricingPage.features.descriptions.ready_made_marketing_templates"
};
export const MARKETING_REQUESTS_WITH_FORMS = {
    name: "pricingPage.features.titles.marketing_requests_with_forms",
    text: "pricingPage.features.descriptions.marketing_requests_with_forms"
};
export const UNLIMITED_TEMPLATES = {
    name: "pricingPage.features.titles.unlimited_templates",
    text: "pricingPage.features.descriptions.ready_made_marketing_templates"
};
export const WORK_PERFORMANCE_INSIGHTS = {
    name: "pricingPage.features.titles.workPerformanceInsights",
    text: "pricingPage.features.descriptions.workPerformanceInsights"
};
export const ASSETS_AND_FILE_MANAGEMENT = {
    name: "pricingPage.features.titles.assets_and_file_management",
    text: "pricingPage.features.descriptions.embed_documents_and_briefs"
};
export const IMPORT_EXPORT_EXCEL = {
    name: "pricingPage.features.titles.import_export_excel",
    text: "pricingPage.features.descriptions.import_export_excel"
};
export const SOCIAL_CALENDAR = {
    name: "pricingPage.features.titles.social_calendar",
    text: "pricingPage.features.descriptions.marketing_calendar"
};
export const SHARE_INSIGHTS_WITH_GUESTS = {
    name: "pricingPage.features.titles.share_insights_with_guests",
    text: "pricingPage.features.descriptions.guests"
};
export const MARKETING_ANALYTICS = {
    name: "pricingPage.features.titles.marketing_analytics",
    text: "pricingPage.features.descriptions.marketing_dashboards"
};
export const DEADLINE_MANAGEMENT = {
    name: "pricingPage.features.titles.deadline_management",
    text: "pricingPage.features.descriptions.deadline_management"
};
export const CAMPAIGN_MANAGEMENT = {
    name: "pricingPage.features.titles.campaign_management",
    text: "pricingPage.features.descriptions.campaign_management"
};
export const HIGH_LEVEL_MARKETING_PLANNING = {
    name: "pricingPage.features.titles.high_level_marketing_planning",
    text: "pricingPage.features.descriptions.high_level_marketing_planning"
};
export const ADOBE_CREATIVE_CLOUD_PLUGIN = {
    name: "pricingPage.features.titles.adobe_creative_cloud_plugin",
    text: "pricingPage.features.descriptions.adobe_creative_cloud_plugin"
};
export const UNLIMITED_PROJECTS_ITEMS_SUBITEMS = {
    name: "pricingPage.features.titles.unlimitedProjectsItemsSubitems",
    text: "pricingPage.features.descriptions.unlimitedProjectsItemsSubitems"
};
export const UNLIMITED_SHAREABLE_FORMS = {
    name: "pricingPage.features.titles.unlimitedShareableForms",
    text: "pricingPage.features.descriptions.unlimitedShareableForms"
};
export const FILE_MANAGEMENT = {
    name: "pricingPage.features.titles.fileManagement",
    text: "pricingPage.features.descriptions.fileManagement"
};
export const GANTT_CHARTS = {
    name: "pricingPage.features.titles.ganttCharts",
    text: "pricingPage.features.descriptions.ganttCharts"
};
export const CUSTOM_NOTIFICATIONS_AND_REMINDERS = {
    name: "pricingPage.features.titles.customNotificationsAndReminders",
    text: "pricingPage.features.descriptions.customNotificationsAndReminders"
};
export const SIMPLE_PROJECT_PORTFOLIO_MANAGEMENT = {
    name: "pricingPage.features.titles.projectPortfolioManagement",
    text: "pricingPage.features.descriptions.projectPortfolioManagement"
};
export const CALENDAR_SYNC = {
    name: "pricingPage.features.titles.calendarSync",
    text: "pricingPage.features.descriptions.calendarSync"
};
export const BASIC_ANALYTICS_AND_DASHBOARDS = {
    name: "pricingPage.features.titles.basicAnalytics",
    text: "pricingPage.features.descriptions.basicAnalytics"
};
export const TASK_AND_PROJECT_DEPENDENCIES = {
    name: "pricingPage.features.titles.taskAndProjectDependencies",
    text: "pricingPage.features.descriptions.taskAndProjectDependencies"
};
export const MILESTONES_MANAGEMENT = {
    name: "pricingPage.features.titles.milestonesManagement",
    text: "pricingPage.features.descriptions.milestonesManagement"
};
export const RESOURCE_ALLOCATION = {
    name: "pricingPage.features.titles.resourceAllocation",
    text: "pricingPage.features.descriptions.resourceAllocation"
};
export const POWERFUL_ANALYTICS_AND_DASHBOARDS = {
    name: "pricingPage.features.titles.powerfulAnalytics",
    text: "pricingPage.features.descriptions.powerfulAnalytics"
};
export const UNLIMITED_PRIVATE_BOARDS = {
    name: "pricingPage.features.titles.unlimitedPrivateBoards",
    text: "pricingPage.features.descriptions.privateBoards"
};
export const ADVANCED_FORMULA_CAPABILITIES = {
    name: "pricingPage.features.titles.advancedFormulaCapabilities",
    text: "pricingPage.features.descriptions.formula"
};
export const SLACK_MSTEAMS_TODOIST_INTEGRATIONS = {
    name: "pricingPage.features.titles.slackMSTeamsTodoistIntegrations",
    text: "pricingPage.features.descriptions.slackMSTeamsTodoistIntegrations"
};
export const ADVANCED_PROJECT_PORTFOLIO_MANAGEMENT = {
    name: "pricingPage.features.titles.advancedProjectPortfolioManagement",
    text: "pricingPage.features.descriptions.advancedProjectPortfolioManagement"
};
export const ENTERPRISE_INTEGRATIONS = {
    name: "pricingPage.features.titles.enterpriseIntegrations",
    text: "pricingPage.features.descriptions.enterpriseIntegrations"
};
export const SPRINT_MANAGEMENT = {
    name: "pricingPage.features.titles.sprint_management",
    text: "pricingPage.features.descriptions.sprint_management"
};
export const KANBAN_MANAGEMENT = {
    name: "pricingPage.features.titles.kanban_management",
    text: "pricingPage.features.descriptions.kanban_management"
};
export const DEV_TEMPLATES = {
    name: "pricingPage.features.titles.dev_templates",
    text: "pricingPage.features.descriptions.dev_templates"
};
export const BUILT_IN_WORKOCS = {
    name: "pricingPage.features.titles.workdocs",
    text: "pricingPage.features.descriptions.workdocs"
};
export const SHARABLE_DEV_BOARDS = {
    name: "pricingPage.products.software.featuresList.shareableBoardsWithExternalStakeholders",
    text: "pricingPage.products.software.featuresList.shareableBoardsWithExternalStakeholders.description"
};
export const GIT_INTEGRATIONS = {
    name: "pricingPage.features.titles.git_integrations",
    text: "pricingPage.features.descriptions.git_integrations"
};
export const AGILE_REPORTING = {
    name: "pricingPage.features.titles.agile_reporting",
    text: "pricingPage.features.descriptions.agile_reporting"
};
export const DEV_FEEDBACK_FORMS = {
    name: "pricingPage.products.software.featuresList.customerFeedbackAndRequests",
    text: "pricingPage.products.software.featuresList.customerFeedbackAndRequests.description"
};
