const VERSION_9 = "9";
const VERSION_10 = "10";
const VERSION_11 = "11";
const VERSION_12 = "12";
const VERSION_13 = "13";
const DEFAULT_VERSION = VERSION_13;
const ALLOWED_VERSIONS = [
    VERSION_9,
    VERSION_10,
    VERSION_11,
    VERSION_12,
    VERSION_13
];
module.exports = {
    VERSION_9,
    VERSION_10,
    VERSION_11,
    VERSION_12,
    VERSION_13,
    DEFAULT_VERSION,
    ALLOWED_VERSIONS
};
