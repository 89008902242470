import styled from "@emotion/styled";
import { ALWAYS_ON_TOP, BASE_TEMPLATE_ROOT } from "constants/z-index";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "/constants/vp-sizes";
export const StyledContactSalesStickyButtonWrapper = styled.div`
  position: fixed;
  top: 80vh;
  right: 0;
  width: 230px;
  z-index: ${ALWAYS_ON_TOP};
`;
export const StyledTemplateContentWrapper = styled.div`
  position: relative;
  z-index: ${BASE_TEMPLATE_ROOT};

  &.with-background-color {
    background-color: white;
  }
`;
export const StyledAccessibilityWrapper = styled.div`
  position: relative;
  z-index: ${BASE_TEMPLATE_ROOT + 1};
`;
export const StyledStickyHeaderWrapper = styled.div`
  position: fixed;
  bottom: 20vh;
  right: 5vw;
  z-index: ${ALWAYS_ON_TOP};
  opacity: 0;
  @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px) {
    right: 40px;
  }

  &.show-sticky-header {
    opacity: 1;
  }
`;
