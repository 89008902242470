function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { PAGE_PERFORMANCE_TRACKING_RESOURCES, PAGE_PERFORMANCE_TRACKING_RESOURCES_ERROR } from "constants/bigbrain-event-types";
const RESOURCES_PATTERN_TO_TRACK = [
    "globe_top_people.png",
    "use.typekit.net",
    "dynamic-template-page.js"
]; //	checking if a string is part of the resource url
const ERROR_KINDS = {
    PERFORMANCE_OBSERVER: "performance_observer",
    GET_ENTRIES_BY_TYPE: "get_entries_by_type"
};
class ResourcesLoadTracking {
    shouldTrackResource(url) {
        return RESOURCES_PATTERN_TO_TRACK.some((resource)=>url.includes(resource));
    }
    handleResources(resources) {
        resources.forEach((resource)=>{
            const { name, responseEnd, responseStart, requestStart, fetchStart, startTime, transferSize } = resource;
            if (this.shouldTrackResource(name)) {
                const rawResourceData = resource.toJSON();
                BigBrain("track", PAGE_PERFORMANCE_TRACKING_RESOURCES, {
                    placement: this.pageLoadUniqueId,
                    kind: name,
                    direct_object_id: startTime,
                    info1: responseEnd,
                    info2: fetchStart,
                    info3: requestStart,
                    direct_object: responseStart,
                    board_kind: transferSize,
                    data: _object_spread({}, rawResourceData)
                });
            }
        });
    }
    trackLoadedResources() {
        try {
            const resources = window.performance.getEntriesByType("resource"); //	until now
            this.handleResources(resources);
        } catch (error) {
            this.trackError(ERROR_KINDS.GET_ENTRIES_BY_TYPE, error.message);
        }
    }
    trackFutureResources() {
        if (!window.PerformanceObserver) {
            //	e.g.: not supported in IE
            this.trackError(ERROR_KINDS.PERFORMANCE_OBSERVER, "not supported");
            return;
        }
        try {
            const observer = new PerformanceObserver((resources, observer)=>{
                const resourceEntries = resources.getEntries();
                this.handleResources(resourceEntries);
            });
            observer.observe({
                entryTypes: [
                    "resource"
                ]
            });
        } catch (error) {
            this.trackError(ERROR_KINDS.PERFORMANCE_OBSERVER, error.message);
        }
    }
    trackError(kind, message) {
        BigBrain("track", PAGE_PERFORMANCE_TRACKING_RESOURCES_ERROR, {
            placement: this.pageLoadUniqueId,
            data: {
                error: message
            },
            kind: kind
        });
    }
    track() {
        this.trackLoadedResources();
        this.trackFutureResources();
    }
    constructor(pageLoadUniqueId){
        this.pageLoadUniqueId = pageLoadUniqueId;
    }
}
//	*Important notes*:
//	some browsers may be blocked, so mind values are bigger than 0
//	some resources comes from cache (0 duration and transferSize, but mind it's not 100% working so probably should clean outliers)
//	mind some resources are loaded on page load (e.g. bundles, fonts, favicon) but some after being called through other scripts
//	times relative to page load
export { ResourcesLoadTracking as default };
